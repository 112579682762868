@import "./ProximaNova/stylesheet.css";

html {
    font-size: 16px;
    font-family: 'Proxima Nova', sans-serif;

}

a,
button {
    transition: 0.15s all ease-in-out;
}

.job-page-layout h1,
.job-page-layout h2,
.job-page-layout h3,
.job-page-layout h4,
.job-page-layout h5,
.montserrat {
    font-family: "Montserrat", sans-serif;
}

select {
    -webkit-appearance: none;
    appearance: none;
    background: url('../img/select-arrow.svg') no-repeat;
    background-position: calc(100% - 20px) 50%;
}

select.gray-arrow {
    background: url('../img/select-arrow-gray.svg') no-repeat;
    background-position: calc(100% - 20px) 50%;
    -webkit-appearance: none;
    appearance: none;
}

.h-\[full\] {
    height: 100%;
}

.h-\[968px\] {
    height: 968px;
}

.bg-\[\#F0F3F8\] {
    --tw-bg-opacity: 1;
    background-color: rgb(240 243 248 / var(--tw-bg-opacity));
}

.lock {
    overflow: hidden !important;
}

.bg-\[\#FFFFFF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.ddd .select-selection {
    border-radius: 0;
}

.select-multi-value-container--secondary .ddd .select-selection {
    border-radius: 6px;
}


.select-selection .bbb {
    min-height: 54px;
    padding-left: 12px;
}

.bbb>.select-multi-value {
    position: relative;
    overflow: hidden;
    background: #E6E7E6;
    border-radius: 6px;
}

.select-multi-value-container--secondary .select-multi-value {
    background-color: #37B34A;
    color: #FFFFFF !important;
}

.select2-dropdown div>div:hover {
    background-color: #37B34A !important;
    color: white !important;
}

.select-multi-value>.select-multi-value {
    color: #999999;
    padding: 5px 10px;
    font-size: 18px;
}

.select-multi-value>.select-multi-value+.select-multi-value {
    /* position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0; */
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 8.5L16 16.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 8.5L8 16.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-size: 24px;
    background-position: center;
    width: 37px;
    height: 37px;
    min-width: 37px;
}

.select-multi-value>.select-multi-value+.select-multi-value svg {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.select-multi-value>.select-multi-value+.select-multi-value:hover {
    background-color: unset;
}

.select-multi-input-wrapper {
    display: flex !important;
}

.select-multi-input-wrapper input {
    flex: auto;
}

.ddd .select-selection-focused,
.ddd .select-selection-focused:hover {
    border-color: #37B34A;
    box-shadow: 0 0 0 1px #37B34A;
}

.pb-\[200px\] {
    padding-bottom: 200px;
}

.pb-\[0px\] {
    padding-bottom: 0px;
}

.slick-dots li button:before {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    opacity: 1;
    content: "";
}

.slick-dots li.slick-active button:before {
    background-color: #37B34A;
}

.slick-prev:before {
    content: '';
    width: 72px;
    height: 72px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../img/Arrow.svg);
}

.slick-next:before {
    content: '';
    width: 72px;
    height: 72px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../img/Arrow.svg);
    transform: rotate(180deg);
}
.container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 20px;
}
.hover\:text-shadow-lg:hover {
    text-shadow: 0 0 1px #003578;
}
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 72px;
    height: 72px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: #37B34A;
}

.slick-prev {
    left: -72px;
}

.slick-next {
    right: -72px;
}

.slick-dots li {
    width: 12px;
    height: 12px;
    margin: 0 6px;
}

.slick-dots {
    margin-top: 70px;
    bottom: -70px;
}

/* radio green */

.radio-green-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.radio-green-sub {
    cursor: pointer;
    position: relative;
    height: 22px;
    width: 22px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
}

.radio-green-sub::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #00000080;
}

.box-shadow {
    box-shadow: 0px 4px 8px 0px rgba(91, 104, 113, 0.24), 0px 0px 1px 0px rgba(26, 32, 36, 0.32);
}

.check-box-fake {
    width: 42px;
    height: 42px;
    flex: 0 0 42px;
    border: 1px solid rgba(222, 222, 217, 1);
    border-radius: 50%;
}

.custopm-radio input:checked~.check-box-fake {
    border: 3px solid white;
    background-color: rgba(55, 179, 74, 1);
    box-shadow: 0px 4px 8px 0px rgba(91, 104, 113, 0.24), 0px 0px 1px 0px rgba(26, 32, 36, 0.32);
}

.radio-green-sub:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #06D6A0;
    transform: translate(-50%, -50%);
    visibility: visible;
}

/* -- radio green */

/* green checkbox */

.checkbox-green-container {}

.checkbox-green-container {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
}

.checkbox-green-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark-green {
    position: relative;
    min-height: 18px;
    min-width: 18px;
    height: 18px;
    width: 18px;
    background-color: white;
    margin-right: 14px;
    overflow: hidden;
    border: 1px solid #00000050;
}

.checkbox-green-container.bigger .checkmark-green {
    min-height: 16px;
    min-width: 16px;
    height: 16px;
    width: 16px;
    margin-right: 24px;
}

.checkbox-green-container.big-28 .checkmark-green {
    min-height: 28px;
    min-width: 28px;
    height: 28px;
    width: 28px;
    margin-right: 24px;
}


.checkbox-green-container input:checked~.checkmark-green {
    /* background-color: #06D6A0; */
    /* border: 1px solid #ffffff80; */
}

.checkbox-green-container input:checked~.checkmark-label-optional {
    color: #37B34A;
}

.checkmark-green:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-green-container input:checked~.checkmark-green:after {
    display: block;
}

.checkbox-green-container.big-28 .checkmark-green:after {
    left: 36%;
    top: 4%;
    width: 10px;
    height: 21px;
    border-width: 0 4px 4px 0;
}

.checkbox-green-container .checkmark-green:after {
    left: 36%;
    top: 4%;
    width: 6px;
    height: 12px;
    border: solid #06D6A0;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-green-container.bigger .checkmark-green:after {
    left: 5px;
    top: 0;
    width: 5px;
    height: 12px;
}

.underline-for-text-part {
    display: block;
    margin: 0 12px;
    width: 230px;
    height: 36px;
    border-bottom: 1px solid #CFCFCF;
}


.input-ap .input-override {
    color: #999999;
    border-color: #DEDED9;
}

.grey-input-custom {
    width: 100%;
    height: 54px;
    padding: 0 18px;
    border: 1px solid #DEDED9;
    border-radius: 6px;
}

.with-num {
    position: relative;
}

.with-num .grey-input-custom {
    position: relative;
    padding: 0 18px 0 40px;
}

.with-num::before {
    content: attr(data-count);
    position: absolute;
    left: 0;
    top: 0;
    font-size: 18px;
    color: #6F6F6F;
    width: 40px;
    height: 54px;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grey-input-custom::placeholder {
    color: #999999;
}

.grey-textarea {
    width: 100%;
    height: 130px;
    padding: 0 18px;
    border: 1px solid #DEDED9;
    border-radius: 6px;
    /* color: #DEDED9; */
    resize: none;
    color: #333333;
}

.grey-input-custom:focus-visible {
    outline: 1px solid #37B34A;
}

.divider-grey {
    background-color: #D9D9D9;
    width: 8px;
    margin: auto;
    height: 1px;
}


.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 300;
    background: rgba(69, 90, 100, 0.25);
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    transition: all 0.15s linear;
    overflow-x: hidden;
    --modal-gutters: 32px;
}

.modal--show {
    opacity: 1;
    visibility: visible;
}

.modal--show .modal__dialog {
    transform: none;
}

.dropdown-deliver {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #D6D6D6;
}

.modal__dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    margin: auto;
    padding: 16px;
    transform: translate(0, -20px);
}

.modal__dialog--840 {
    max-width: 880px;
}

.modal__dialog--600 {
    max-width: 640px;
}

.modal__dialog--1000 {
    max-width: 1040px;
}

.modal__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    transition: all 0.3s ease-out;
    position: relative;

}

.numbers-dropdoun>button {
    z-index: 2;
}

.numbers-dropdoun.dropdoun--active>button:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -8px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 16px solid white;

}

.dropdown {
    position: relative;
}

.dropdown.active .dropdown__body {
    display: block;
}

.dropdown__body {
    display: none;
    margin-top: 8px;
    position: fixed;
    top: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 8px 0px #5B68713D, 0px 0px 1px 0px #1A202452;
    border-radius: 12px;
    z-index: 12;
}

.dropdown__body-list {
    min-width: 140px;
}

.dropdown__body-list li a {
    display: block;
    width: 100%;
    padding: 12px 28px;
}

.numbers-dropdoun__list {
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: 4px;
    background-color: white;
    min-width: 293px;
    transform: translateX(-50%);
    box-shadow: 0px 4px 8px 0px #5B68713D, 0px 0px 1px 0px #1A202452;
    padding: 26px;
}

.modal__body {
    padding: var(--modal-gutters);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modal__header {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background-color: #F2F2F2;
    border-radius: 6px 6px 0 0;
}

.modal__footer {
    padding: var(--modal-gutters);
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    border-radius: 0 0 6px 6px;
}

.modal__footer-border {
    position: relative;
}

.modal__footer-border:after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - (var(--modal-gutters) * 2));
    height: 1px;
    background-color: #DEDED9;
    left: var(--modal-gutters);
    top: 0;
}

.modal__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 21px;
}

.btn-close {
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    right: 12px;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    /* display: none;
    -webkit-appearance: none;
    width: 15px;
    padding: 0px;
    margin: 0px;
    margin-top: 10px; */
    background-image: url('../img/calendar-icon.svg');
}

.green-box {
    width: 8px;
    height: 8px;
    background-color: #37B34A;
}

.date-green-input-container {
    position: relative;
}

.date-green-input-container::after {
    content: '';
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 15px;
    right: 15px;
    /* background-color: #37B34A; */
    background-image: url('../img/calendar-green-icon.svg');
}


.table-10_14 th,
.table-10_14 td {
    border: 1px solid #DEDED9;
}

.table-10_14 {
    color: #666666;
}

.table-10_14 th {
    background-color: #F7F7F7;
}

.input-green-point {
    position: relative;
}

.input-green-point::after {
    content: '';
    position: absolute;
    background-color: #37B34A;
    width: 12px;
    height: 12px;
    border-radius: 360px;
    top: 20px;
    left: 20px;
}

.input-green-point input {
    padding-left: 35px;
}


.table-10_14_4 th,
.table-10_14_4 td {
    border: 1px solid #DEDED9;
    min-height: 56px;
    font-size: 18px;
}

.table-10_14_4 {
    color: #666666;
}

.table-10_14_4.second-table th {
    font-size: 16px;
    padding: 0 16px;
    height: 56px;
    white-space: nowrap;
}

.table-10_14_4.second-table tr td:first-child {
    padding: 18px 24px;
}

.table-10_14_4.second-table tr td {
    padding: 16px 0px;
    height: 134px;
}

.table-10_14_4.second-table .second-table-total td {
    height: 80px;
}

.table-10_14_4.second-table .radio-col {
    width: 80px;
    max-width: 80px;
}

.table-10_14_4.second-table .label-fixed {
    display: block;
    margin-left: -5px;
}

.table-10_14_4.second-table .comment-col {
    width: 290px;
    max-width: 290px;
}

.table-10_14_4.firts-table td {
    border-bottom: unset;
    height: 56px;
}

.table-10_14_4 th {
    background-color: #F7F7F7;
}

.description-tip-modal-sbm {
    display: none;
    position: absolute;
    width: 646px;
    left: 0;
    bottom: 45px;
    z-index: 10;


    flex-direction: column;
    align-items: self-start;
    gap: 12px;

    background-color: #FFFFFF;
    padding: 36px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    /*border: 1px solid #8a8a8a;*/
    border-radius: 16px;
    text-align: left;
}

.description-tip-modal-sbm-container:hover .description-tip-modal-sbm {
    display: flex;
}

.description-tip-modal-sbm::after,
.description-tip-modal-sbm .description-tip-modal-sbm-triangle {
    content: '';
    position: absolute;
    bottom: -35px;
    left: 30px;
    border-width: 19px;
    border-style: solid;
    z-index: 11;
    border-color: #FFFFFF transparent transparent transparent;
}

.description-tip-modal-sbm .description-tip-modal-sbm-triangle {
    position: fixed;
    top: auto;
    bottom: auto;
    left: auto;
}

.description-tip-modal-sbm.have-triangle::after {
    display: none;
}


.modal-sbm {
    /* overflow: scroll; */

    padding: 20px;
    position: fixed;
    background: #FFFFFF;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;

    display: flex;
    align-items: center;
    /*justify-content: center;*/
    flex-direction: column;
}

.modal-sbm::-webkit-scrollbar,
.secondary-menu-tabs::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.modal-sbm-body {
    display: flex;
    /* min-height: calc(100dvh - 115px); */
    flex: auto;
    overflow: auto;
}

.modal-sbm>* {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.modal-sbm>*>button {
    flex-shrink: 0;
}


.select-field-green {
    cursor: pointer;
}

.select-field-green:focus-visible {
    outline: 1px solid #37B34A;
}

.mail-trianlge::after {
    content: '';
    display: block;
    position: absolute;
    left: -20px;
    transform: translateY(-100%) rotate(270deg);
    top: 50%;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 16px solid white;

}

.header-link::after {
    content: "";
    position: absolute;
    bottom: -45px;
    width: 100%;
    height: 5px;
    background-color: #37B34A;
    opacity: 0;
    transition: 0.3s;
    position: relative;
    display: flex;
}

.header-link.active::after {
    opacity: 1;
}

.text-wrap,
.text-balance,
.text-pretty {
    white-space: normal;
}

@supports (text-wrap: balance) {
    .text-balance {
        text-wrap: balance;
    }
}

/* input[type="time" i]::-webkit-calendar-picker-indicator {
    display: none;
    opacity: 0;
    appearance: none;
} */

textarea:disabled {
    -webkit-text-fill-color: #333333;
    opacity: 1
}

.header-dropdown {
    position: absolute;
    /*width: 100%;*/
    background: white;
    /*bottom: -53px;*/
    display: none;
    flex-direction: column;
    color: black;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #ececec;
}

.header-dropdown.white {
    /*bottom: -33px;*/
}

.white-login-drop {
    height: max-content;
    margin: auto 0;
}

.header-dropdown.open {
    display: flex;
}

.header-drop-links {
    padding: 0 15px;
}

.header-drop-links:hover {
    background-color: rgb(240 243 248);
}

.top-hiring-plans li:not(:last-child) {
    padding: 4px;
    margin-bottom: 13px;
}

button.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.article {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    gap: 20px;
}

.article h1,
.article h2,
.article h3,
.article h4,
.article h5,
.article h6 {
    font-size: 32px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
}

p+.article h1,
p+.article h2,
p+.article h3,
p+.article h4,
p+.article h5,
p+.article h6 {
    margin-top: 20px;
}


@media (max-width: 1279.98px) {
    .header-link::after {
        bottom: -25px;
    }
}

@media (max-width: 1023.98px) {
    .header-link::after {
        bottom: 0px;
    }

    .header-link {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 768px) {
    .modal {
        --modal-gutters: 22px;
    }

    .underline-for-text-part {
        width: 100%;
    }

    .radio-green-container {
        justify-content: flex-start;
    }

    .job-page-layout .radio-green-sub::before {
        width: 22px;
        height: 22px;
    }

    .job-page-layout .radio-green-sub:checked::after {
        width: 10px;
        height: 10px;
    }

    .description-tip-modal-sbm::after {
        display: none;
    }
}


.time-picker {
    padding-right: 32px;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 512 512'%3E%3Cpath d='M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z' /%3E%3C/svg%3E");
}

.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100px;
}

.public-page {
    h2, h3, div, span, p {
        line-height: normal;
    }
}

.classic-styles {
    ol, ul, menu {
    list-style: unset;
    margin: 10px;
    padding: 10px;
    }
}

.blog-single {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    gap: 20px;
}

.blog-single h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    margin: revert;
}

.blog-single h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin: revert;
}

.blog-single h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    margin: revert;
}

.blog-single h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    margin: revert;
}

.blog-single h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    margin: revert;
}

.blog-single h6 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin: revert;
}

p + .blog-single h1,
p + .blog-single h2,
p + .blog-single h3,
p + .blog-single h4,
p + .blog-single h5,
p + .blog-single h6 {
    margin-top: 20px;
}

.wrap-anywhere {
    overflow-wrap: anywhere;
}
