#sign-letter-wrapper .rte-toolbar-mobile {
    display: none !important;
}

#sign-letter-wrapper .rte-toolbar-desktop {
    display: none !important;
}

#sign-letter-wrapper .richtexteditor.rte-modern rte-bottom {
    display: none !important;
}