.swiper {
    width: 100%;
    max-width: 510px;
    min-height: 610px;
}



.swiper-with-buttons {
    width: 100%;
    max-width: unset;
    min-height: unset;
    padding: 35px 10px 10px 10px;

    .swiper-slide-next {
        box-shadow: unset !important;
    }
    /*.swiper-slide {*/
    /*    background-color: red !important;*/
    /*    height: 100% !important;*/
    /*    min-height: 100% !important;*/
    /*}*/

}

.swiper-custom {
    width: 100%;
    max-width: unset;
    min-height: unset;
    padding: unset;
    overflow: visible;

    .swiper-wrapper {
        transition-timing-function: linear !important;
    }

    .swiper-slide-next {
        box-shadow: unset !important;
    }
}

.swiper-custom-join {
    width: 100%;
    max-width: unset;
    min-height: unset;
    padding: 10px 20px 5px 20px;

    .swiper-slide-next {
        box-shadow: unset !important;
    }
    height: max-content;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
}


.swiper-cards .swiper-slide {
    overflow: unset;
}

.swiper-3d .swiper-wrapper {
    transform-style: unset;
    opacity: unset;
    backdrop-filter: unset;
}

.swiper-3d .swiper-slide-shadow {
    background: rgba(0, 0, 0, 0.02);
}

.swiper-slide-next {
    box-shadow: 0px 0px 31.15px 0px rgba(0, 0, 0, 0.11) !important;
}


.swiper-slide:nth-child(1n) {
    background-color: transparent;
}

.swiper-slide:nth-child(2n) {
    background-color: transparent;
}

.swiper-slide:nth-child(3n) {
    background-color: transparent;
}

.swiper-slide:nth-child(4n) {
    background-color: transparent;
}

.swiper-slide:nth-child(5n) {
    background-color: transparent;
}

.swiper-slide:nth-child(6n) {
    background-color: transparent;
}

.swiper-slide:nth-child(7n) {
    background-color: transparent;
}

.swiper-slide:nth-child(8n) {
    background-color: transparent;
}

.swiper-slide:nth-child(9n) {
    background-color: transparent;
}

.swiper-slide:nth-child(10n) {
    background-color: transparent;
}
