.card-shadow {
    border-radius: 21px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.28);
}

.input-phone-wrapper .react-tel-input .form-control {
    width: 100%;
    border-radius: 10px;
    background: #EBF0F3;
    height: 72px;
    font-size: 20px;
    padding-left: 90px;
    border: 0;
}

@media (width < 768px) {
    .input-phone-wrapper .react-tel-input .form-control {
        height: 52px;
        font-size: 16px;
    }
}

.input-phone-wrapper .react-tel-input .special-label {
    display: none;
}

.input-phone-wrapper .react-tel-input .selected-flag {
    width: 68px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 0 0 10px;
}

.input-phone-wrapper .react-tel-input .flag-dropdown {
    background: #EBF0F3;
    border: 0;
    border-right: 1px solid #000;
    border-radius: 10px 0 0 10px;
}

.input-phone-wrapper .react-tel-input .selected-flag:hover {
    background: #e1e6e8;
}

/* .input-phone-wrapper .react-tel-input .selected-flag .arrow {
    display: none;
} */

.custom-checkbox-blue {
    display: flex;
    align-items: center;
    gap: 17px;
}

.input-phone-wrapper .react-tel-input .form-control:focus {
    border-color: #37B34A;
    box-shadow: 0 0 0 2px #37B34A;
}

.custom-checkbox-blue input:checked + .fake-check {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none"><circle cx="13" cy="13" r="13" fill="%230056B3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M19.6913 8.33053C20.1029 8.77123 20.1029 9.48575 19.6913 9.92645L11.2162 19L7.30873 14.8166C6.89709 14.3759 6.89709 13.6613 7.30873 13.2206C7.72036 12.7799 8.38775 12.7799 8.79938 13.2206L11.2162 15.8082L18.2006 8.33053C18.6123 7.88982 19.2796 7.88982 19.6913 8.33053Z" fill="white"/></svg>');
}

/* .scroll-style::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.scroll-style::-webkit-scrollbar-track {
    background: transparent;
}
.scroll-style::-webkit-scrollbar-button {
    background: #888;
    border-radius: 50px;
} */
.scroll-style::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}

.scroll-style::-webkit-scrollbar-track {
    background-color: transparent;
}

.scroll-style::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}

.pagination-style {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.pagination-style li a {
    display: block;
    width: 100%;
    height: 100%;
    color: #25404D;
    padding: 5px 7px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
}

.pagination-style li.selected a {
    pointer-events: none;
}

.pagination-style li.selected a,
.pagination-style li:not(.break):hover a {

    background-color: #25404D;
    color: white;
}

.pagination-style li.break a {
    user-select: none;
    pointer-events: none;
}

.video-player {
    width: 100%;
    height: 100%;
    max-height: 580px;
    aspect-ratio: 16/7;
    border-radius: 21px;
    background: rgba(44, 44, 44, 0.18);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.28);
    overflow: hidden;
    min-height: 250px;
    max-height: 580px;
}

.react-player__preview {
    position: relative;
}

.calculator {
    padding: 40px;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.28);
    /*background-color: #faf7e4;*/
    background-color: #ecfdf5;
     /*background: linear-gradient(to bottom, rgb(235, 233, 213), #faf7e4),*/
     /*            no-repeat center center/cover;*/
}

.calculator-input-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 5%;
    margin-top: 12px !important;
}

.calculator-input-label {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 1.5rem !important;
}

.calculator-input-label.total {
    font-size: 18px !important;
}

.calculator-input-wrapper input {
    width: 300px;
    border: 1px solid #CFCFCF;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: white;
    font-weight: 500;
}

.calculator-base-wrapper {
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin-left: 5%;
}

.calculator-base-wrapper label {
    font-weight: 400;
    min-width: 330px;
}

@media (max-width: 768px) {
    .calculator {
        padding: 20px;
    }

    .calculator-input-wrapper {
        flex-direction: column;
        margin-left: unset;
        align-items: unset;
    }

    .calculator-input-wrapper input {
        width: 100%;
    }

    .calculator-base-wrapper {
        margin-left: unset;
    }

    .calculator-base-wrapper label {
        min-width: unset;
        flex: 1;
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
    }
}
